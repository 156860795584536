@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=sap");
@media (max-width: 767px) {
  :root {
    --linkHP: 3px;
  }
}
@media (min-width: 768px) {
  :root {
    --linkHP: 16px;
  }
}
.header {
  position: relative;
  font-family: var(--font-family-title);
  display: flex;
  justify-content: center;
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9);
  font-size: 20px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .header {
    justify-content: space-between;
    font-size: 14px;
  }
  .header .button-link:first-child {
    margin-left: calc(var(--linkHP) * -1);
  }
  .header .button-link:last-child {
    margin-right: calc(var(--linkHP) * -1);
  }
}
@media (max-width: 350px) {
  .header {
    font-size: 11px;
  }
}

.main-page-link {
  position: absolute !important;
  left: calc(var(--linkHP) * -1);
}
@media (max-width: 767px) {
  .main-page-link {
    position: relative !important;
    left: unset;
  }
}

.logo {
  display: none;
  position: relative;
  left: unset;
  font-family: "Julius Sans One", sans-serif;
}
@media (max-width: 767px) {
  .logo {
    display: block;
    font-size: 24px;
  }
  .logo .header {
    margin-bottom: 40px;
  }
}

.sublogo {
  position: absolute;
  font-size: 20px;
  right: 40px;
  top: 50px;
  text-transform: lowercase;
  text-align: right;
  font-weight: lighter;
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.04em;
}
@media (max-width: 767px) {
  .sublogo {
    font-size: 10px;
    right: 16px;
    top: 40px;
  }
}

.footer {
  font-family: "Quicksand", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: lighter;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .footer {
    font-size: 16px;
    align-items: start;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 16px;
  }
}

.footer-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}
.footer-row a, .footer-row .button-link {
  color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 767px) {
  .footer-row a, .footer-row .button-link {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .footer-row {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.rules {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .rules {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .copyrigths {
    padding: 0 16px;
  }
}

* {
  box-sizing: border-box;
}

html {
  --font-family-main: "Quicksand", sans-serif;
  --font-family-title: "Julius Sans One", sans-serif;
}
html[lang=ru] {
  --font-family-main: "Montserrat", sans-serif;
  --font-family-title: "Montserrat", sans-serif;
  font-weight: 300;
}

body {
  font-family: var(--font-family-main);
  font-size: 42px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
}
body ::selection {
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
}
body .invert-text {
  color: #ffffff;
}
body .invert-text ::selection {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.9);
}
@media (max-width: 767px) {
  body {
    font-size: 16px;
  }
}

.mobile {
  display: none;
}
@media (max-width: 767px) {
  .mobile {
    display: unset;
  }
}

@media (max-width: 767px) {
  .desktop {
    display: none;
  }
}

.main-wrapper {
  position: relative;
  overflow: hidden;
  min-width: 320px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
}
@media (max-width: 767px) {
  .main-wrapper {
    padding: 0 16px;
  }
}

.hidden {
  display: none;
}

a,
.link,
.button-link {
  cursor: pointer;
  color: unset;
  text-decoration: unset;
  position: relative;
  border-bottom: unset;
}
a:hover,
.link:hover,
.button-link:hover {
  border-bottom: unset;
}
a.link,
.link.link,
.button-link.link {
  transition: border-bottom background-color 0.2s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9);
}
a.link:hover,
.link.link:hover,
.button-link.link:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
a.button-link,
.link.button-link,
.button-link.button-link {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 5px var(--linkHP);
  border-radius: 3px;
  transition: background-color 0.3s ease;
}
a.button-link:hover,
.link.button-link:hover,
.button-link.button-link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

img {
  width: 100%;
  height: auto;
  border-radius: 3px;
}

button {
  background: unset;
  border: unset;
  line-height: unset;
  font-weight: unset;
}

.button {
  font-family: var(--font-family-title);
  transition: background-color 0.2s ease;
  width: 325px;
  padding: 27px 0;
  position: relative;
  border: unset;
  border-radius: 3px;
  cursor: pointer;
  font-size: 20px;
  text-transform: uppercase;
  color: #ffffff;
  border: unset;
  background-color: rgba(0, 0, 0, 0.9);
}
.button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.button:disabled {
  opacity: 0.25;
  color: rgba(0, 0, 0, 0.9);
  background-color: #ffffff;
  cursor: #ffffff;
}
.button .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.button:not(.loading) .loader {
  display: none;
}
.button.loading span {
  visibility: hidden;
}
.button .icon {
  margin-left: 21px;
}
@media (max-width: 767px) {
  .button {
    width: 100%;
  }
}

h1,
h2,
h3 {
  font-family: var(--font-family-title);
  margin: unset;
  margin-block: unset;
  margin-inline: unset;
  font-weight: 300;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

h1 {
  font-size: 48px;
  letter-spacing: 0.04em;
}
@media (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}

.h1-enorme {
  font-size: 56px;
}
@media (max-width: 767px) {
  .h1-enorme {
    font-size: 16px;
    width: 100%;
  }
}

h2 {
  font-size: 42px;
}
@media (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 42px;
}
@media (max-width: 767px) {
  h3 {
    font-size: 16px;
  }
}

p,
.p {
  font-size: 36px;
  margin: 0;
  margin-inline: unset;
}
@media (max-width: 767px) {
  p,
  .p {
    font-size: 16px;
  }
}
p .first-line,
.p .first-line {
  display: block;
  text-align: right;
}
p.light,
.p.light {
  font-weight: 300;
}

.p-grande {
  font-size: 42px;
  width: 900px;
}
@media (max-width: 767px) {
  .p-grande {
    font-size: 16px;
    width: 100%;
  }
}

section {
  position: relative;
  margin: 160px 0;
  display: flex;
  justify-content: center;
  gap: 40px;
}
@media (max-width: 767px) {
  section {
    margin: 40px 0;
    flex-direction: column;
    gap: 20px;
  }
  section .button {
    width: 100%;
  }
}

.section-bg-image-ful {
  background-position: center 55%;
  min-height: 700px;
  background-size: 100%;
  margin-left: -40px;
  margin-right: -40px;
  padding: 60px 40px;
}
.section-bg-image-ful .section-content {
  justify-content: flex-start;
}
.section-bg-image-ful .section-content h2 {
  width: 75%;
}
.section-bg-image-ful .section-content p {
  width: 50%;
}

@media (max-width: 767px) {
  .section-desktop {
    display: none;
  }
}

.section-mobile {
  display: none;
}
@media (max-width: 767px) {
  .section-mobile {
    display: flex;
  }
}

.section-content {
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.section-content.center {
  justify-content: center;
}
.section-content.at-corners {
  justify-content: space-between;
}
.section-content.at-corners p {
  align-self: end;
  width: 60%;
}
@media (max-width: 767px) {
  .section-content.poster {
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
  }
  .section-content.bg-image-ful {
    position: relative;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
    height: 200px;
    background-position: center 55%;
    background-size: 100%;
  }
  .section-content.bg-image-ful p {
    justify-self: end;
  }
}

.section-title {
  display: flex;
  height: 368.4210526316px;
  align-items: end;
}
@media (max-width: 767px) {
  .section-title {
    height: auto;
    align-items: start;
  }
}

.below-text {
  display: flex;
  justify-content: end;
}
.below-text p {
  width: 70%;
}
@media (max-width: 767px) {
  .below-text p {
    width: 100%;
  }
}

.image-half {
  width: auto;
  height: 700px;
}
@media (max-width: 767px) {
  .image-half {
    width: 100%;
    height: auto;
  }
}

.gallery {
  flex-direction: column;
  gap: 0;
  align-items: center;
}

.gallery-content {
  border-radius: 3px;
  overflow: hidden;
}

.gallery-row {
  display: flex;
}

.gallery-item {
  display: flex;
}
.gallery-item img {
  height: 350px;
  width: auto;
  border-radius: 0;
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
@media (max-width: 767px) {
  .gallery-item img {
    width: 100%;
    height: auto;
  }
}
.gallery-item .back {
  display: none;
}
.gallery-item:hover .back {
  display: block;
}
.gallery-item:hover .front {
  display: none;
}

.logoSvg {
  position: absolute;
  width: 800px;
  height: auto;
  top: -130px;
  right: 0;
  z-index: -100;
}
@media (max-width: 767px) {
  .logoSvg {
    width: 50%;
    top: -135px;
  }
}

@media (max-width: 767px) {
  .section-momentos .section-content {
    position: relative;
    color: rgba(0, 0, 0, 0.9);
    padding: 0;
  }
  .section-momentos .section-content a,
  .section-momentos .section-content .link {
    color: #ffffff;
    border-color: #ffffff;
  }
}
@media (max-width: 767px) {
  .section-momentos .image-half {
    margin-top: -60px;
  }
}

@media (max-width: 767px) {
  .shop-button {
    width: 100%;
    position: relative;
  }
}

.header .main-page-link {
  display: none;
}

.logo {
  display: block;
  font-size: 128px;
  font-family: "Julius Sans One", sans-serif;
}
@media (max-width: 767px) {
  .logo {
    font-size: 48px;
    text-align: center;
  }
}

.sublogo {
  font-size: 28px;
}
@media (max-width: 767px) {
  .sublogo {
    position: relative;
    text-align: center;
    font-size: 20px;
    right: unset;
    top: unset;
  }
}